import axios from 'axios'
import _ from 'lodash'
import { API_URL } from '../config'

class Rest {
    constructor() {
        this.endpoint = `${API_URL}/rest`
        this.token = localStorage.getItem('_token')
        this.endpointAnyPrint = `${API_URL}/anyprint/product-types`
    }

    getToken = () => {
        return localStorage.getItem('_token')
    }

    /**
     *
     */
    request = (method, site_id, endpoint, data) => {
        return new Promise((rs, rj) => {
            let requestOptions = {
                url: `${this.endpoint}/${site_id}`,
                method: method,
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                withCredentials: true,
                params: { endpoint: endpoint },
                data: data,
            }
            axios(requestOptions)
                .then((res) => {
                    if (_.get(res, 'data.errors')) return rj(res.data.errors)
                    return rs(res.data)
                })
                .catch((err) => rj(err))
        })
    }

    /**
     * Get productTypes anyPrint
     */

    getProductTypeAnyPrint = async (filter, body) => {
        const resp = await axios.get(`${this.endpointAnyPrint}?page=${filter.page}&limit=${filter.limit}`, { params: body })
        return resp.data
    }
}

export default new Rest()
